import { Fragment } from "react";
import { useState } from "react";

const DateCard = (props) => {
  const noop = () => {};
  const {
    month,
    day,
    year,
    handleRemoveLeave
  } = props;
  const [showCancelInterruptBtn, setCancelInterruptBtn] = useState(false);

  const handleMouseOver = (event) => {
    setCancelInterruptBtn(true);
  };

  const handleMouseOut = (event) => {
    setCancelInterruptBtn(false);
  }

  const onClickAction = showCancelInterruptBtn ? handleRemoveLeave : noop;
  const cursorType = showCancelInterruptBtn ? ' cursor-pointer' : '';

  return (
    <div>
      <div
        className={`grid shadow-md w-48 h-48 bg-white rounded-md justify-center justify-items-center items-center${cursorType}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClickAction}
      >
        {
          showCancelInterruptBtn ? (
            <p className="text-red-500 cursor-pointer">Remove leave</p>
          ) : (
            <Fragment>
              <p className="text-3xl">{month}</p>
              <p className="text-7xl">{day}</p>
              <p className="text-2xl">{year}</p>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

DateCard.defaultProps = {
  month: 'February',
  day: '6',
  year: '1996',
  handleRemoveLeave: () => console.log('removed')
};

export default DateCard;
