import { Fragment } from "react";

const DateCardSkeleton = (props) => {
  return (
    <div>
      <div
        className={`grid animate-pulse shadow-md w-48 h-48 bg-white rounded-md justify-center justify-items-center`}
      >
        <Fragment>
          <p className="text-3xl"></p>
          <p className="text-7xl"></p>
          <p className="text-2xl"></p>
        </Fragment>
      </div>
    </div>
  );
};

DateCardSkeleton.defaultProps = {
};

export default DateCardSkeleton;
