import axios from 'axios';

function Axios() {
  const request = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
  });

  request.interceptors.response.use((response) => response, async (error) => {
    if (!error.response?.data) {
      error.response = !!error.response ? error.response : {
        data: {},
        status: 500,
        statusText: '',
        headers: {},
        config: {}
      };

      error.response.data = {
        message: 'Cannot connect to the API'
      }
    }

    if ([401].includes(error.response.status) && error.config) {
      const originalRequest = error.config;
      localStorage.removeItem('access_token');

      return request(originalRequest);
    }

    if ([403].includes(error.response.status)) {
      localStorage.removeItem('access_token');
    }

    throw error;
  });

  return request;
}

export default Axios();