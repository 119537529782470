import './index.css';
import { Fragment } from "react";
import { useAuthentication } from '../../Context/Auth';
import { useSnackbar } from '../../Context/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  menuPadding: {
    flexGrow: 1
  },
  menu: {}
}));

const Topbar = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { user, isAuthenticated, forgetCredential } = useAuthentication();

  const Logout = (event) => {
    forgetCredential();
    history.push('/');
    snackbar.show('Logout successfully', 'success');
  }

  return (
    <Fragment>
      <div className="min-h-screen min-w-full">
        {
          isAuthenticated && (
            <AppBar position="sticky" color="inherit">
              <Toolbar>
                <div className={styles.menuPadding}></div>
                <div className={styles.menu}>
                  <IconButton onClick={Logout}>
                    <Avatar alt={user.name} src={user.avatar} />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          )
        }
        <div className="min-h-screen min-w-full topbar-margin">
          { props.children }
        </div>
      </div>
    </Fragment>
  );
};

export default Topbar;