import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import './App.css';
import { AuthenticationProvider } from './Context/Auth';
import GuestRoute from './Component/GuestRoute';
import ProtectedRoute from './Component/ProtectedRoute';
import { SnackbarProvider } from './Context/Snackbar';

import Topbar from './Component/Topbar';
import Login from './Ui/app.login';
import Dashboard from './Ui/app.dashboard';


function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <SnackbarProvider>
          <AuthenticationProvider>
            <Router>
              <Topbar>
                <Switch>
                  <GuestRoute exact path="/login" component={Login} />
                  <ProtectedRoute exact path="/" component={Dashboard} />
                </Switch>
              </Topbar>
            </Router>
          </AuthenticationProvider>
        </SnackbarProvider>
      </div>
    </Router>
  );
}

export default App;
