import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthentication } from '../../Context/Auth';

const GuestRoute = ({ component: Component, render, ...props }) => {
  const { isAuthenticated } = useAuthentication();

  return (
    <Route {...props} render={ (innerProps) => {
      if (isAuthenticated) {
        return <Redirect to={{
          pathname: "/",
          state: { prev: innerProps.location }
        }} />;
      }

      if (Component) {
        return <Component {...innerProps} />
      }

      return (render)(innerProps);
    }} />
  );
};

export default GuestRoute;
