import { Fragment } from 'react';
import DateCard from '../DateCard';
import DateCardSkeleton from '../DateCardSkeleton';

const DateContainer = (props) => {
  const { data, isLoaded, onCardRemove } = props;

  const LoadCards = () => {
    if (!isLoaded) {
      return (
        <Fragment>
          <DateCardSkeleton />
          <DateCardSkeleton />
          <DateCardSkeleton />
          <DateCardSkeleton />
          <DateCardSkeleton />
        </Fragment>
      );
    }

    if (!data.length) {
      return (
        <div className="mt-20">
          <p className="text-4xl text-yellow-400">No upcoming leaves</p>
        </div>
      );
    }

    return data.map((item) => {
      const removeLeave = () => {
        return onCardRemove(item.id);
      }
      return (
        <DateCard
          {...item}
          key={item.id}
          handleRemoveLeave={removeLeave}
        />
      );
    });
  };

  return (
    <div className="grid grid-flow-col gap-4">
      <LoadCards />
    </div>
  );
};

DateContainer.defaultProps = {
  data: [],
  isLoaded: false,
  onCardRemove: () => {}
};

export default DateContainer;
