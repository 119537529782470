import { Fragment } from 'react';
import 'loaders.css';

const ShowLoader = (props) => {
  if (!props.ready) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <div>
          <div className="loader">
            <div className="pacman">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return props.content;
};

const WaitScreenOverlay = (props) => {
  const { ready, children } = props;

  return (
    <Fragment>
      <ShowLoader
        content={children}
        ready={ready}
      />
    </Fragment>
  );
};

export default WaitScreenOverlay;
