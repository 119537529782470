import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React, { createContext, useContext, useState } from 'react';

const Context = createContext({
  open: true,
  message: '',
  color: 'error',
  show: (message, color) => {}
});

export const useSnackbar = () => useContext(Context);

export const SnackbarProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('default message');
  const [color, setColor] = useState('info');

  const handleClose = () => setOpen(false);

  const show = (message, color) => {
    setOpen(true);
    setMessage(message);
    setColor(color);
  }

  return (
    <Context.Provider value={{ open, message, color, show }}>
      { props.children }

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert
          severity={color}
          variant="filled"
          color={color}
          onClose={handleClose}
        >
          { message }
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
}
