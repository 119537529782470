import dayjs from 'dayjs';
import customFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customFormat);

const transformInterrupts = (data) => {
  try {
    data = data.interrupts;

    return data.map((item) => {
      const { date } = item;
      const [year, month, day] = dayjs(date.toString(), 'YYYYMMDD').format('YYYY-MMMM-DD').split('-');
  
      return {
        id: item.date,
        month,
        day,
        year
      };
    });
  } catch (error) {
    throw error;
  }
};

export default transformInterrupts;
