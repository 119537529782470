import { useReducer, useCallback } from 'react';
import reducer, { initialState } from './reducer';
import types from './types';
import api from '../../Lib/Request';
import { useAuthentication } from '../../Context/Auth';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import customFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customFormat);

const useRequest = () => {
  const { forgetCredential } = useAuthentication();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  const post = useCallback(async (url, value, params) => {
    dispatch({
      type: types.LOADING,
      data: state.data
    });

    try {
      const token = localStorage.getItem('access_token');

      const config = {
        params,
        headers: {
          Authorization: token
        }
      };
      const [year, month, day] = dayjs(value.toString(), 'YYYYMMDD').format('YYYY-MMMM-DD').split('-');

      const body = {
        date: value
      };

      await api.post(url, body, config);

      dispatch({
        type: types.LOADED,
        data: [
          ...state.data,
          {
            id: value,
            year,
            month,
            day
          }
        ]
      });
    } catch (error) {
      if ([401, 403].includes(error.response.status)) {
        forgetCredential();
        history.push('/login');
        return;
      }

      dispatch({
        type: types.ERROR,
        data: error.response.data
      });
    }
  }, [history, forgetCredential, state]);

  const get = useCallback(async (url, params, mapFunction) => {
    dispatch({
      type: types.LOADING
    });

    try {
      const token = localStorage.getItem('access_token');

      const config = {
        params,
        headers: {
          Authorization: token
        }
      };

      const response = await api.get(url, config);

      let data = response.data;

      if (mapFunction) {
        data = mapFunction(data);
      }

      dispatch({
        type: types.LOADED,
        data
      });
    } catch (error) {
      if ([401, 403].includes(error.response.status)) {
        forgetCredential();
        history.push('/login');
        return;
      }

      dispatch({
        type: types.ERROR,
        data: error.response.data
      });
    }
  }, [history, forgetCredential]);

  const remove = useCallback(async (url, id) => {
    dispatch({
      type: types.LOADING,
      data: state.data
    });

    try {
      const token = localStorage.getItem('access_token');

      const newDate = _.remove([ ...state.data ], (item) => {
        return item.id !== id;
      });
  
      const config = {
        headers: {
          Authorization: token
        }
      };
  
      await api.delete(`${url}/${id}`, config);

      dispatch({
        type: types.LOADED,
        data: newDate
      });
    } catch (error) {
      if ([401, 403].includes(error.response.status)) {
        forgetCredential();
        history.push('/login');
        return;
      }

      dispatch({
        type: types.ERROR,
        data: error.response.data
      });
    }
  }, [state, history, forgetCredential]);

  return {
    state,
    remove,
    post,
    get
  }
};

export default useRequest;
