import './index.css';
import { GoogleLogin } from 'react-google-login';
import { useAuthentication } from '../../Context/Auth';
import { useSnackbar } from '../../Context/Snackbar';

const Login = () => {
  const { setCredential } = useAuthentication();
  const snackbar = useSnackbar();

  const LoginAction = (params) => {
    const user = {
      email: params.profileObj.email,
      avatar: params.profileObj.imageUrl,
      name: params.profileObj.name,
      token: params.tokenId
    }; 
    
    setCredential(user);

    snackbar.show(`Welcome ${user.name}!`, 'success');
  }

  const LoginFailAction = (error) => {
    snackbar.show('Login failed', 'error');
  }

  return (
    <div className="grid min-w-full max-w-full min-h-screen max-h-screen justify-center content-center">
      <div>
        <GoogleLogin
          disabled={false}
          clientId={process.env.REACT_APP_CLIENT_ID}
          buttonText="Login with Google"
          onSuccess={LoginAction}
          onFailure={LoginFailAction}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    </div>
  );
};

export default Login;
