import React from 'react';
import { useAuthentication } from '../../Context/Auth';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, render, ...props }) => {
  const { isAuthenticated } = useAuthentication();

  return (
    <Route {...props} render={ (innerProps) => {
      if (!isAuthenticated) {
        return <Redirect to={{
          pathname: "/login",
          state: { prev: innerProps.location }
        }} />;
      }

      if (Component) {
        return <Component {...innerProps} />
      }

      return (render)(innerProps);
    }} />
  )
};

export default ProtectedRoute;
