import {
  createContext, useContext, useEffect, useState
} from 'react';
import { useSnackbar } from '../Snackbar';
import request from '../../Lib/Request';
import WaitScreenOverlay from '../../Component/WaitScreenOverlay';

const defaultUser = {
  name: '',
  email: '',
  avatar: ''
};

const Context = createContext({
  user: { ...defaultUser },
  token: '',
  isAuthenticated: false,
  setCredential: () => {},
  forgetCredential: () => {}
});

export const AuthenticationProvider = (props) => {
  const { show } = useSnackbar();
  const [user, setUser] = useState(defaultUser);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access_token');

    const getMe = async (accessToken) => {
      try {
        const response = await request.get('/me', {
          headers: {
            Authorization: accessToken
          }
        });
    
        const { email, name, picture, token } = response.data;
        
        localStorage.setItem('access_token', token);
    
        setUser({
          avatar: picture,
          email,
          name
        });
        setAuthenticated(true);
        setReady(true);
      } catch (error) {
        localStorage.removeItem('access_token');
  
        throw error;
      }
    }

    if (!token) {
      setAuthenticated(false);
      setReady(true);
      return;
    }

    getMe(token);
  }, [isAuthenticated, ready]);

  const setCredential = async (obj) => {
    try {
      const response = await request.get('/me', {
        headers: {
          Authorization: obj.token
        }
      });

      const { email, name, picture, token } = response.data;

      setUser({
        email,
        name,
        avatar: picture
      });

      localStorage.setItem('access_token', token);
      setAuthenticated(true);
      setReady(true);

    } catch (error) {
      show('Login failed', 'error');

      throw error;
    }
  };

  const forgetCredential = () => {
    localStorage.removeItem('access_token');
    setAuthenticated(false);
    setReady(true);
  }

  return (
    <Context.Provider value={{
      setCredential,
      forgetCredential,
      user,
      isAuthenticated,
      ready
    }}>
      <WaitScreenOverlay ready={ready}>
        { props.children }
      </WaitScreenOverlay>
    </Context.Provider>
  );
};

export const useAuthentication = () => useContext(Context);
