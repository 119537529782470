import './index.css';
import { useEffect, useState } from 'react';
import DateContainer from '../../Component/DateContainer';
import DatePicker from '../../Component/DatePicker';
import transformInterrupts from './transformInterrupts';
import useRequest from '../../Hooks/useRequest';
import { useSnackbar } from '../../Context/Snackbar';

const Dashboard = () => {
  const [loaded, setLoaded] = useState(false);
  const request = useRequest();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (!loaded) {
      request.get('/interrupts', {}, transformInterrupts);
      setLoaded(true);
    }
  }, [loaded, request]);

  const handleRemoveDate = (id) => {
    request.remove('/interrupts', id);
    snackbar.show(`Leave for ${id} has been removed`, 'success');
  };

  const handleAddDate = (date, value) => {
    request.post('/interrupts', +value);
    snackbar.show(`Leave for ${value} has been added`, 'success');
  };

  return (
    <div className="grid mx-auto justify-center justify-items-center min-w-full mt-16 grid-cols-12">
      <div className="row-span-1 col-span-12">
        <p className="text-6xl text-white mb-11">
          Add a leave
        </p>
      </div>
      <div className="row-span-1 col-span-12 w-96">
        <DatePicker
          handleDateChange={handleAddDate}
        />
      </div>
      <div className="row-span-1 col-span-12 mt-12">
          <p className="text-6xl text-white mb-11">
            Upcoming leaves
          </p>
      </div>
      <div className="row-span-8 col-span-12 mt-5">
        <DateContainer
          data={request.state.data}
          isLoaded={loaded}
          onCardRemove={handleRemoveDate}
        />
      </div>
    </div>
  );
};

export default Dashboard;
