import types from './types';

export const initialState = {
  status: null,
  data: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING:
      return { ...initialState, status: types.LOADING, data: action.data };
    case types.LOADED:
      return { ...state, status: types.LOADED, data: action.data };
    case types.ERROR:
      return { ...state, status: types.ERROR, data: action.data };
    default:
      return state;
  }
};

export default reducer;
