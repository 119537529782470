import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ededed',
      main: '#ffffff',
      dark: '#c7c5c5',
      contrastText: '#fff',
    },
  },
});

const DatePicker = (props) => {
  const { date, handleDateChange, label } = props;

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            disablePast={true}
            id="date-picker-dialog"
            label={label}
            format="yyyyMMdd"
            value={date}
            fullWidth={true}
            color="secondary"
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

DatePicker.defaultProps = {
  date: new Date(),
  handleDateChange: () => {},
  label: 'Pick-a-date'
}

export default DatePicker;
